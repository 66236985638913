import { render, staticRenderFns } from "./AllDoctorList.vue?vue&type=template&id=73e45caa&"
import script from "./AllDoctorList.vue?vue&type=script&lang=js&"
export * from "./AllDoctorList.vue?vue&type=script&lang=js&"
import style0 from "./AllDoctorList.vue?vue&type=style&index=0&id=73e45caa&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports